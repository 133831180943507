body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.imagen-ajustada {
  max-height: 250px; /* Limita la altura máxima a 250 px */
  width: auto;       /* Ajusta el ancho automáticamente según la proporción */
  display: block;    /* Necesario para que funcione margin: auto */
  margin: 0 auto;    /* Centra la imagen horizontalmente */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.marker-label {
  /* Color del texto */
  pointer-events: none; /* Evitar eventos del ratón sobre el texto */
  text-align: center;


  font-size: 16px;
  font-weight: bold;
  text-shadow: -2px -2px 2px #ffffff, 2px 2px 2px #ffffff, -2px 2px 2px #ffffff, 2px -2px 2px #ffffff; 
  -webkit-text-fill-color: #fc0000;
  

}

.popover-content {
  position: absolute;
  left: 15px;
  gap: 0px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 12px;
  box-shadow: 0px 6px 10px 0px #00000057;
  overflow-y: auto;     /* Habilita el desplazamiento vertical */
  overflow-x: hidden;   /* Evita el desplazamiento horizontal */
  padding: 10px;
  min-width: 258px;
  max-width: 350px;
  min-height: 180px;
  max-height: 600px;
  font-family: 'Merriweather';
  z-index: 98;

}
.expanded {
  height: auto;
  max-height: 300px; /* Ajusta según sea necesario */
  overflow-y: auto; /* Añade barras de desplazamiento si el contenido es demasiado largo */
}
.popover-more-content {
 
  gap: 0px;

  left:1px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 12px;
  box-shadow: 0px 6px 10px 0px #00000057;
  padding: 10px;
  min-width: 360px;
  max-width: 0px;
  min-height: 75vh;
  text-justify: auto;
  font-family: 'Merriweather';
  position: fixed; /* Agregamos esta propiedad para que el div esté por encima de todo */
  left: -400px;
  z-index: 9999; /* Un valor alto de z-index para asegurarnos de que esté por encima */
}

.bw {
  filter: grayscale(80%);
}
#myButton {
  background-color: #396FED;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
#myButton img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
/* Estilo del título */
.popover-content h2 {
  font-size: 18px;
  margin-bottom: 5px;
}

/* Estilo del cuerpo */
.popover-content p {
  font-size: 14px;
  margin-bottom: 0;
}

.close-button {
  background-color: #E3C46B;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  top:5px;
  right: 4px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.close-button:hover {
  background-color: #ad944a;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}
.readmore-button {
  background-color: #1B877C;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  bottom: 5px;
  right: 4px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.readmore-button:hover {
  background-color: #156860;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}
.popover-content ul li::marker {
  color: #1C887D; /* Cambia el color del marcador */
  font-size: 20px;
}


.scrollable-content {
  /* Estilos para WebKit (Chrome, Safari, Opera) */
  ::-webkit-scrollbar {
    width: 8px; /* Ancho de la barra de desplazamiento */
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Color de fondo de la pista de la barra de desplazamiento */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #4caf50; /* Color del pulgar (barra de desplazamiento) */
    border-radius: 4px; /* Borde redondeado del pulgar */
  }

  /* Estilos para Firefox */
  scrollbar-color: #24887e #f1f1f1; /* Color del pulgar y de la pista, respectivamente */
  scrollbar-width: thin; /* Ancho de la barra de desplazamiento */
}


@media only screen and (max-width: 600px) {
  /* Estilos específicos para dispositivos móviles */
  div[style="position: absolute; bottom: 0; right: 0;"] {
      width: 100%;
  }
  div[style="display: flex; flex-direction: column; background: rgba(255, 255, 255, 0.8);"] {
      padding: 10px;
  }
  div[style="display: flex; flex-direction: column; align-items: center;"] {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
  }
  div[style*="border: 1px solid black; padding: 5px; flex: 1; margin: 5px; minWidth: 100px; text-align: center;"] {
      width: calc(33.33% - 10px);
  }
}